import $ from "jquery";
import Swiper from 'swiper';
import { Fancybox } from "@fancyapps/ui";

window.$ = $;
window.Fancybox = Fancybox;


$(document).ready(function () {
    if (document.documentElement.clientWidth <= 575) {
        let itemsSliders = document.getElementsByClassName('item-detail__left');

        Array.from(itemsSliders).forEach(function (slider) {
            new Swiper(slider, {
                loop: true,
                speed: 800,
                spaceBetween: 0,
                slidesPerView: 1,
                navigation: {
                    nextEl: slider.parentElement.getElementsByClassName('custom-slider__arrow--next')[0],
                    prevEl: slider.parentElement.getElementsByClassName('custom-slider__arrow--prev')[0]
                },
                pagination: false,
                breakpoints: {}
            });
        })
    }

    const sidebar = document.querySelector('#sidebar');

    if (sidebar) {
        if (document.documentElement.clientWidth >= 1025) {
            $(function () {
                const $sidebar = $('#sidebar__inner');
                const $section = $('.def-section--big.hide-mob');
                var width = $sidebar.width();
                var left = $sidebar.position().left;
                document.documentElement.style.setProperty('--left', `${left}px`);
                document.documentElement.style.setProperty('--sidebar', `${width}px`);


                $(window).resize(function () {
                    document.documentElement.style.removeProperty('--sidebar');
                    document.documentElement.style.removeProperty('--left');
                    document.documentElement.style.setProperty('--sidebar', `${$('#sidebar__inner').width()}px`);
                    document.documentElement.style.setProperty('--left', `${$('#sidebar__inner').position().left}px`);
                });

                if ($section.length === 0)
                    return;

                var top = $sidebar.offset().top - parseFloat($sidebar.css('marginTop').replace(/auto/, 0));
                var footTop = $section.offset().top - parseFloat($section.css('marginTop').replace(/auto/, 0));

                var maxY = footTop - $sidebar.outerHeight();

                $(window).scroll(function () {
                    var y = $(this).scrollTop() - 55;
                    if (y >= top - $('.header').height()) {
                        if (y < maxY - 150) {
                            $sidebar.addClass('fixed').removeAttr('style').removeClass('absolute');
                        } else {
                            $sidebar.removeClass('fixed').css({
                                position: 'absolute',
                                top: (maxY - top + 241) + 'px'
                            }).addClass('absolute');
                        }
                    } else {
                        $sidebar.removeClass('fixed').removeClass('absolute');
                    }
                });
            });
        }
    }

    const itemZoom = document.querySelector('.ds-item-zoom-main');
    const itemZoomTabs = document.querySelector('.ds-item-zoom-tabs');

    new Swiper(itemZoom, {
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: false,
        allowTouchMove: false,
        effect: 'fade',
        navigation: {
            nextEl: '.ds-item-zoom-next',
            prevEl: '.ds-item-zoom-prev'
        }
    });

    new Swiper(itemZoomTabs, {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 16,
        direction: 'vertical',
        loop: false,
        allowTouchMove: true,
        slideToClickedSlide: true
    });

    if (document.documentElement.clientWidth > 575) {
        Fancybox.bind('[data-fancybox-card]', {
            infinite: false,
            groupAll: true,
            mainClass: 'fancy-custom'
        });
    } else {
        document.querySelectorAll('[data-fancybox-card]')?.forEach(oItem => {
            oItem.href = 'javascript:void(0)';
        });
    }
});
